// モジュール読み込み
// ============================================================

//import Vue from 'vue';
import * as func from './functions/_function';                        //関数関係
import * as animation from './functions/_animation';                  //アニメーション関係
//import * as bliderBox from './functions/_bliderBox'                   //ブラインダーボックス
import * as offcanvas from './functions/_offcanvas';                  //オフキャンバス
//import * as pagetop from './functions/_pagetop';                      //ページトップ
import * as loading from './functions/_loading';                      //ローディング
//import * as smoothScroll from './functions/_smoothScroll';            //スムーススクロール
//import Modal from '../../node_modules/bootstrap/js/dist/modal';       //モーダルライブラリ
import 'mobile-detect';                                               //デバイス判定・ブラウザ判定ライブラリ
import * as checkUA from './functions/_checkUA';                      //デバイス判定・ブラウザ判定処理
//import 'fullpage.js';

// 定数
// ============================================================

const BLAKEPOINT_HEADER_SP_MODE = 9999;                               //ヘッダーレスポンシブブレイクポイント
const DEBUG_MODE                = false;                              //デバッグモード

// グローバル変数
// ============================================================

let CURRENT_SCROLL_POS = 0;
let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let SCROLLDOWN_FLAG           = true;

// サイト共通関数初期化
// ============================================================

animation.scrollObserver();                                            // アニメーション処理
checkUA.addUAClass();                                                  // UAおよびデバイスクラス追加処理
//headerFixed.setHeaderFixed();                                          // ヘッダー固定
//smoothScroll.setSmoothScroll();                                        // スムーススクロール
//bliderBox.setBliderBox();                                              // ブラインダーボックス初期化
offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');               // オフキャンバス処理

// イベント毎のサイト共通関数初期化
// ============================================================

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
})


// 固有関数実行
// ============================================================

$(function(){
  pageHomeScript();
});

// トップページ固有
// ============================================================

function pageHomeScript() {
  
  if ($('.home').length) {
    loading.initLoading(DEBUG_MODE);
  }
}

// ローディング完了後にアニメーションを発火させる関数
// ============================================================

function loadingCallbackAnimation(delay,cb){
  
  // ローディング完了フラグを監視
  let watchLoadedFlagClass = setInterval(function () {
    countUp();
  }, 1);
  
  const countUp = () =>{
    if($('body').hasClass('js_isLoaded')){
      
      setTimeout(function () {
        
        //引数で渡された関数を実行
        cb();
      }, delay);
      clearInterval(watchLoadedFlagClass);
      
    }
  }
}

// ローディング時にコールバックさせるアニメーション関数をセット/制御
// ------------------------------------------------------------

function setLoadingCbAnimation() {
  
  //ターゲットクラスの有無を確認
  if($('.js_loading_cb_animation').length){
    
    let targetElms = $('.js_loading_cb_animation');
    
    targetElms.each(function(i){
      let delay = $(this).data('animation-delay');
      $(this).delay(i * delay).queue(function(next){
        $(this).addClass('js_isActive animate__animated');
        next();
      });
    })
  }
}

loadingCallbackAnimation(600,setLoadingCbAnimation);